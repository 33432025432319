@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

/**/
:root {
  --dark-text: #2b2a2e;
  --primary-color: #91c6bc;
  --primary-color-light: rgb(205, 232, 227);
}
body {
  margin: 0;
  font-family: "Montserrat";
  background-color: var(--primary-color);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexCol {
  display: flex;
  flex-direction: column;
}

.white-border {
  border-style: solid;
  border-color: white;
  border-width: 3px;
  border-radius: 20px;
}

/*App*/
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-container {
  align-items: center;
  justify-content: space-evenly;
  max-width: 843px;
  min-width: 200px;
  margin: 0px 15px;
}
.body {
  justify-content: space-around;
  width: 100vw;
  align-items: center;
}

/*Navbar*/
.navbar {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

header a {
  color: white;
  text-decoration: none;
}

.navbar h1 {
  letter-spacing: 0.4em;
  transform: translateX(30px);
  margin-bottom: 10px;
}

.navbar .line {
  width: 100%;
  height: 3px;
  background-color: white;
}

/*Container with Timer/etc.*/
.flowmodoro-app {
  margin-top: 5vh;
  align-items: stretch;
  width: fit-content;
  justify-content: center;
}
.flowmodoro-app .taskName {
  max-width: 672px; /*has to be px not percentage*/
  /*stop expanding title*/
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.flowmodoro-app h2 {
  margin: 0;
  font-weight: normal;
}
.module-container {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
  padding: 40px;
  margin: 5px;
  align-items: stretch;
  width: auto;
  overflow: hidden;
  height: auto;
  justify-content: center;
  column-gap: 27px;
  align-self: center;
}

.other-container {
  justify-content: space-between;
}
.taskbox-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
}
/*Timer*/
.stopwatch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
  padding: 40px;
  margin: 5px; */
}

.stopwatch-container button {
  background-color: rgba(255, 255, 255, 0.5);
  border: 0;
  color: var(--dark-text);
  padding: 10px 20px 10px 20px;
  /* letter-spacing: 0.1em; */
  width: fit-content;
  margin: 0px;
  font-size: 2em;
  font-family: "Montserrat";
  cursor: pointer;
  border-radius: 10px;
  margin: 0px 10px;
}
.stopwatch-container button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
.timeText {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-variant-numeric: tabular-nums;
}
.timeText h1 {
  font-size: 100px;
  margin: 0;
  font-weight: normal;
}
.timeText h2 {
  font-size: 45px;
  margin: 0;
  font-weight: normal;
}
.time {
  align-items: center;
  padding: 20px;
}

/*Interruptions*/
.interruptions-box {
  justify-content: center;
  padding: 10px;
}
.interruptions-box .btn {
  font-size: 2em;
  color: var(--dark-text);

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1em;
}
.interruptions-box .btn:hover {
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.interruptions-box h1 {
  font-size: 3em;
  margin: 0px;
}
.interruptions-box h3 {
  color: var(--dark-text);
  margin: 0px;
  text-align: center;
}
.interruptions-box .counter {
  justify-content: space-evenly;
}

/*Estimated Break Time Box*/
.estimated-break h3 {
  color: var(--dark-text);
  margin: 0px;
  text-align: center;
  font-size: 1em;
}
.estimated-break {
  justify-content: center;
  padding: 10px;
  align-items: center;
}

/*Tasks Container*/
.task-container {
  margin-top: 10px;
}
.task-container .line {
  height: 1px;
  background-color: white;
}
.task-container h2 {
  font-weight: normal;
  margin: 0px;
}
.task-container .tasks-title {
  justify-content: space-between;
}
.tasks-title .more {
  font-size: 2em;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 0.2em;
  margin-bottom: 5px;
  margin-left: 5px;
}
.tasks-title .more:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
}
/*Tasks*/
.task-card {
  color: var(--dark-text);
  background-color: rgba(255, 255, 255);
  border-radius: 1.5em;
  margin-top: 5px;
}
.task {
  align-items: center;
  align-content: stretch;
  justify-content: space-around;
}
.task-line {
  background-color: var(--primary-color);
  height: 1px;
}
.task .checkbox {
  margin-left: 2%;
  flex-grow: 1;
}
.task .task-name {
  flex-grow: 5;
  width: 70%;
}
.task input {
  font-family: "Montserrat";
  font-size: 18.72px; /*h3*/
  color: var(--dark-text);
  margin: 18.72px 0px;
  font-weight: bold;
  width: 95%;
  background: transparent;
}
.task input:focus {
  outline: none !important;
  border: 1px dashed var(--dark-text);
}
.task .input-disabled {
  border: 0;
}
.task .expand-btn {
  flex-grow: 1;
}
.task .delete-btn {
  flex-grow: 1;
}
.task .btn:hover {
  cursor: pointer;
}
.task-card.selected {
  border-color: var(--dark-text);
  border-style: solid;
  border-width: 2px;
}
.task-card.done {
  opacity: 0.5;
}
.task-card.done h3 {
  text-decoration: line-through;
}
.task-card .task-name:hover {
  cursor: pointer;
}

.flowmo-added-animation {
  animation: flowmo-added 0.8s ease-in-out;
  /* animation-fill-mode: none; */
  animation-direction: alternate;
  animation-iteration-count: 2;
}

@keyframes flowmo-added {
  to {
    color: white;
    border-color: white;
    background-color: var(--primary-color);
  }
}

/*Table*/
.table {
  color: var(--dark-text);
  background-color: rgba(255, 255, 255);
  border-radius: 1.5em;
  margin-top: 5px;
  max-width: 100%;
  padding: 10px;
}
table {
  width: 100%;
}
.table tr {
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: space-around;
  border-bottom: 1px solid black;
}
.table td .scrollable {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.table td .scrollable::-webkit-scrollbar {
  height: 5px;
}

.table td .scrollable::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: grey;
}
.table td .scrollable::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
/*Task header*/
.table th,
.table td {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: auto;
  border-right: 1px solid black;
  text-align: center;
}
.table th:nth-child(1),
td:nth-child(1) {
  width: 32%;
}
/*Interruptions header*/
.table th:nth-child(6),
.table td:nth-child(6) {
  width: 5%;
  border-right: 0px;
}
.table tr:hover {
  background-color: var(--primary-color-light);
}
.table tr:nth-child(1):hover {
  background-color: transparent;
}

/*CSV Button*/
.CSV-download {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CSV-download a {
  text-decoration: none;
}
.csv-button {
  color: var(--dark-text);
  background-color: rgba(255, 255, 255, 0.5);
  width: fit-content;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  align-self: center;
  cursor: pointer;
}
.csv-button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}
.csv-button h3 {
  margin: 0px;
  font-size: smaller;
}
/*TaskTable*/
.task-table {
  color: var(--dark-text);
  border-radius: 1.5em;
  max-width: 100%;
  padding: 10px;
}
.task-table tr {
  width: 100%;
  display: flex;
  align-items: left;
  justify-content: space-around;
}
.task-table .footer th,
.task-table .footer td {
  border-bottom: none;
}
.task-table tr.flowmo-row:hover td {
  background-color: var(--primary-color-light);
}
.task-table tr.flowmo-row:hover td:nth-child(6) {
  background-color: rgba(0, 0, 0, 0);
}
.material-symbols-outlined.cancel-btn {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
  color: rgb(244, 67, 67);
  cursor: pointer;
}

.task-table th,
.task-table td {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid black;
  text-align: center;
  width: 20%;
  height: auto;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.task-table th:nth-child(1),
.task-table td:nth-child(1),
.task-table th:nth-child(6),
.task-table td:nth-child(6) {
  width: 10%;
  border: 0px;
}
.task-table th:nth-child(5),
.task-table td:nth-child(5) {
  border-right: 0px;
}

/*Breaktime*/
.module-container .breaktime {
  max-width: 150px;
  justify-content: center;
  align-items: stretch;
}

/*Settings*/
.settings-container {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-container .settings-box {
  width: 400px;
  height: auto;
  padding: 20px;
  background-color: var(--primary-color);
  border-radius: 30px;
  margin: 5px;
  align-items: center;
  border-color: white;
  border: solid 3px;
  font-size: 0.9em;
}
.settings-container .close-btn:hover {
  cursor: pointer;
}

.settings-box .flexRow {
  width: 80%;
}
.settings-box .flexRow h2 {
  font-weight: normal;
  margin: 10px;
}
.settings-box .flexRow.closeRow {
  width: 100%;
  padding-left: 10px;
  align-items: stretch;
  justify-content: space-between;
}
.settings-box .flexRow.closeRow h1 {
  font-weight: normal;
  margin-bottom: 0px;
}
.settings-box .flexRow input {
  width: 30px;
  font-size: 1.2em;
  border-radius: 4px;
}
.settings-box .flexRow.alarm-input {
  align-items: flex-start;
}
.settings-box button {
  background-color: rgba(255, 255, 255, 0.5);
  border: 0;
  color: var(--dark-text);
  padding: 10px;
  width: fit-content;
  margin: 0px;
  font-size: 1.5em;
  font-family: "Montserrat";
  cursor: pointer;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 0.67em;
}
.settings-box button:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 874px) {
  .module-container {
    flex-direction: column;
    row-gap: 27px;
  }
  .other-container {
    flex-direction: row-reverse;
    column-gap: 8%;
    justify-content: space-between;
  }
  .interruptions-box,
  .estimated-break {
    width: 40%;
  }
  .flowmodoro-app .taskName {
    max-width: 498px; /*has to be px not percentage*/
  }
  .breakBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .module-container .breaktime {
    max-width: 100%;
  }
  .breaktime h2 {
    font-size: calc(1.5rem + 1vw);
  }
  .breaktime p {
    font-size: calc(0.6rem + 1vw);
  }
}

@media (max-width: 677px) {
  /*Task Table*/
  .task-table th {
    overflow: auto;
    white-space: nowrap;
  }
  tr,
  th {
    font-size: smaller;
  }

  .flowmodoro-app .taskName {
    font-size: 20px;
    max-width: 352px; /*has to be px not percentage*/
  }
  .navbar h1 {
    font-size: calc(1.6rem + 1vw);
    margin-left: 20px;
  }
  .module-container {
    padding: 6%;
    row-gap: 1em;
  }
  .other-container {
    column-gap: 1.5em;
  }
  .timeText h1 {
    font-size: calc(4.8rem + 1vw);
  }
  .flowmodoro-app .timeText h2 {
    font-size: calc(2.4rem + 1vw);
  }
  .stopwatch-container {
    margin: 0px;
  }
  .stopwatch-container button {
    padding: 8px 18px 8px 18px;
    font-size: calc(1.7em + 1vw);
  }
  .time {
    align-items: center;
    padding: 15px;
  }

  .interruptions-box h3,
  .estimated-break h3 {
    font-size: calc(0.7rem + 1vw);
  }
  .interruptions-box h1 {
    font-size: calc(2em + 1vw);
  }
  .interruptions-box .btn {
    font-size: calc(1.8em + 1vw);
    font-weight: none;
  }
  /*Settings*/
  .settings-container .settings-box {
    width: 65%;
    font-size: calc(0.4rem + 1vw);
  }
  .settings-box .break-input {
    width: 100%;
    justify-content: center;
    column-gap: 5px;
  }
  .settings-box .break-input h2,
  .settings-box .flexRow.alarm-input h2 {
    font-size: calc(0.8rem + 1vw);
    margin-left: 0px;
    margin-right: 0px;
  }
  .settings-box .flexRow input {
    font-size: calc(1em + 1vw);
    width: 10px;
  }
  .settings-box .flexRow.alarm-input {
    justify-content: center;
    width: 100%;
  }
  @media (max-width: 568px) {
    .timeText h1 {
      font-size: calc(3rem + 1vw);
    }
    .flowmodoro-app .timeText h2 {
      font-size: calc(1.2rem + 1vw);
    }
    .flowmodoro-app .taskName {
      font-size: 1.1em;
    }

    .navbar h1 {
      letter-spacing: 0.4em;
      transform: translateX(10px);
    }


  }
  @media (max-width: 382px) {
    .page-container {
      margin: 0px;
    }
    .module-container {
      padding: 5px;
    }
    .navbar h1 {
      font-size: calc(1.2rem + 1vw);
    }
    .flowmodoro-app .taskName {
      font-size: 1em;
      max-width: 280px; /*has to be px not percentage*/
    }
    .stopwatch-container button {
      padding: 6px 16px 6px 16px;
      font-size: calc(1.5em + 1vw);
    }
    .task-container h2 {
      font-size: calc(1.1rem + 1vw);
    }
    .task input {
      font-size: calc(0.9rem + 1vw);
    }
    .settings-box .break-input h2,
    .settings-box .flexRow.alarm-input h2 {
      font-size: calc(0.6rem + 1vw);
    }
  }
}
